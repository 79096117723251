.backgroundVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.navbar {
  background-color: black;
  height: 90px;
  box-shadow: 0 9px 12px 0 rgb(0 0 0 / 49%);
  background: linear-gradient(to bottom, #000 0, rgba(13, 12, 14, .70196) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
}

.navItem {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: .08em;
  font-weight: 700;
  margin: 0 10px;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  display: flex;
  align-items: center;
  transition: color .2s ease-out;
}

.navItemSelected {
  color: var(--highlight-color);
  text-shadow: 0 0 3px var(--highlight-color);
}

@media(hover) {
  .navItem:hover {
    color: var(--highlight-color);
    text-shadow: 0 0 3px var(--highlight-color);
  }
  .iconContainer:hover .icon {
    color: var(--highlight-color);
    text-shadow: 0 0 3px var(--highlight-color);
  }
}

.childrenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  height: 100%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  color: white;
  font-size: 1.2em;
  margin-left: ;
  transition: color .2s ease-out;
}

.loaderContainer {
  position: fixed;
  top: 0;
  background-color: black;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--highlight-color);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}