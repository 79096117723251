.container {
  background-color: black;
}

.proyectsContainer {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: min-content;
  /* background-color: red; */
  padding: 10px;
}