.proyect {
  width: 560px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.13);
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.proyectImage {
  width: 100%;
  background-color: #444;
  min-height: 300px;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.proyectTitle {
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 900;
  color: var(--highlight-color);
  margin: 10px 0;
}

.proyectBody {
  line-height: 1.3em;
  color: #ccc;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.proyectBody > * + * {
  margin-top: 0.5em;
}


.proyectBody a {
  color: var(--highlight-color);
}

.proyectBody b {
  text-shadow: 0px 0px 2px var(--highlight-color);
}

@media(hover) {
  .proyectBody a:hover {
    text-shadow: 0 0 3px var(--highlight-color);
  }
}