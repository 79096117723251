.container {
  width: min-content;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; 
}

.background {
  width: 100vw;
  max-width: 500px;
}

.foreground {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 43%;
}

.mark {
  width: 1px;
  height: 1px;
  top: -90px;
  position: absolute;
}
