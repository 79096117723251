.container {
  width: 100vw;
  position: relative;
}

.bodyContainer {
  background-color: black;
  width: 100%;
}

.body {
  width: 480px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 480px) {
  .body {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 13px;
  }
}

.blob {
  position: absolute;
  bottom: 100%;
  z-index: -1;
  animation: yo-yo 5s infinite alternate;
}

@keyframes yo-yo {
  from { 
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0, 20px);
  }
}

.title {
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-size: 3em;
}

.subtitle {
  text-transform: uppercase;
  letter-spacing: 0.4em;
  font-size: 1em;
  color: var(--highlight-color);
}

.endContainer {
  height: 100px;
  overflow: hidden;
}
