.container {
  background-color: black;
}

.textContainer {
  width: 480px;
  margin: 0 auto;
  text-align: center;
}

.text {
  text-shadow: 1px 1px 3px var(--highlight-color);
  text-transform: uppercase;
  list-style: 40px;
  font-size: 1em;
  letter-spacing: 0.42px;
  font-weight: 900;
  line-height: 2em;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .textContainer {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 13px;
  }
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 40px;
  text-decoration: none;
}

.icon {
  color: white;
  margin: 0 10px;
  text-shadow: 1px 1px 3px var(--highlight-color);
}

@media(hover) {
  .icon:hover {
    color: var(--highlight-color);
  }
}