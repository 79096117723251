:root {
  --highlight-color: #C692FE;
}

* {
  padding: 0;
  margin: 0;
}
html,
body {
  color: white;
  font-family: Montserrat,sans-serif;
}
